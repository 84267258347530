import React from "react";
import Navbar from "./Navbar";
import { FaArrowRight } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";

const HomeLayout = () => {
	return (
		<>
			<div className="bg-[#000] py-2 text-white flex justify-center items-center gap-4 px-6 md-px-8">
				<p className="text-[11px] lg:text-[16px]">
					GET 10% OFF FOR SHARING A REVIEW WITH US (USE CODE REVIEW10K AT
					CHECKOUT){" "}
				</p>
				<p>
					<FaArrowRight />
				</p>
			</div>
			<Navbar />

			<Outlet />

			<footer className="bg-[#000] py-4 flex justify-between items-center px-6 lg:px-44">
				<Link to="/faq" className="text-white">
					FAQs
				</Link>
				<Link to="/about" className="text-white">
					About Us
				</Link>
				<Link to="/privacy" className="text-white">
					Privacy Policy
				</Link>
			</footer>
		</>
	);
};

export default HomeLayout;
