import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Home1 from "../assests/images/home/8.png";
import Home2 from "../assests/images/home/9.png";
import Home3 from "../assests/images/home/5.png";
import Home4 from "../assests/images/home/6.png";
import Home5 from "../assests/images/home/7.png";
import Sp1 from "../assests/images/home/Adi Kaimowitz.png";
import Sp2 from "../assests/images/home/Napsta.png";
import Sp3 from "../assests/images/home/Dr Ali.png";
import Sp4 from "../assests/images/home/Natalie Barlow.png";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
    const [slidesToShow, setSlidesToShow] = useState(window.innerWidth >= 1024 ? 4 : window.innerWidth >=568 ? 2 : 1);

    const industries = [
        "Technology",
        "Finance",
        "E-commerce",
        "Professional Services",
        "Healthcare",
        "Education",
        "Retail"
    ];

    useEffect(() => {
        const handleResize = () => {
            setSlidesToShow(window.innerWidth >= 1024 ? 4 : window.innerWidth >=568 ? 2 : 1 );
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handlePrev = () => {
        setCurrentIndex(prev => Math.max(prev - 1, 0));
    };

    const handleNext = () => {
        setCurrentIndex(prev => Math.min(prev + 1, industries.length - slidesToShow));
    };

    const displayedIndustries = industries.slice(currentIndex, currentIndex + slidesToShow);


	return (
		<>
			<section className="py-24 bg-black w-full relative">
				<div className="absolute left-16 top-6 w-[250px] h-[250px] rounded-[30px] overflow-hidden hidden lg:block">
					<img src={Home1} alt="" className="w-full h-full" />
				</div>
				<div className="w-[100%] lg:w-[50%] m-auto text-center">
					<h1 className="text-white text-[30px] lg:text-[65px] font-bold">
						The Scaling <br />
						Success <span className="text-[#f40804]">Summit</span>
					</h1>
					<p className="text-white text-[17px] lg:text-[23px] pt-5 pb-10">
						27 JULY | THE MICHELANGELO TOWERS
					</p>
					<div className="flex w-full flex-col lg:flex-row gap-3 px-6 justify-center items-center">
						<Link
							to="/register"
							className="bg-[#09cbcd] px-8 lg:px-12 py-3 rounded-full font-bold lg:text-[17px]"
						>
							Apply to Exhibit
						</Link>
						<Link
							to="/register"
							className="bg-[#fff] px-5 lg:px-8 py-3 rounded-full font-bold lg:text-[17px] capitalize"
						>
							Become a sponsor
						</Link>
					</div>
				</div>
				<div className="absolute right-16 bottom-6 w-[250px] h-[250px] rounded-[30px] overflow-hidden hidden lg:block">
					<img src={Home2} alt="" className="w-full h-full" />
				</div>
			</section>
			<section className="bg-black lg:pt-10 w-full hidden lg:block">
				<div className="w-[90%] lg:w-[80%] py-5 bg-white rounded-e-[30px] text-right pr-3 lg:px-16 flex flex-col items-end justify-center gap-4">
					<h1 className="lg:text-[40px] font-semibold">
						“THE #1 EVENT FOR ORGANIZATIONS TO ATTEND”
					</h1>
					<p className="lg:text-[20px] font-medium italic">
						{" "}
						- Arash Rezaei | Start X Founder
					</p>
				</div>
			</section>
			<section className="bg-black lg:py-10">
				<div className="w-[90%] lg:w-[70%] m-auto text-white text-center py-10">
					<p className="text-[25px] lg:text-[40px] font-semibold pb-5">
						Empowering Businesses to Grow.
					</p>
					<p className="lg:text-[23px] font-medium">
						Connect with industry leaders, gain proven growth strategies &
						unlock explosivegrowth at the Scaling Success Summit.
					</p>
				</div>
			</section>

			<section className="bg-white py-16">
				<div className="w-[90%] m-auto">
					<div className="flex flex-col lg:flex-row justify-center items-center w-full gap-5 lg:px-20">
						<div className="lg:w-[50%] order-2 lg:order-1">
							<h2 className="text-[40px] font-semibold pb-5">
								Key Event Features
							</h2>
							<ul className="list-disc px-5 font-medium text-[22px]">
								<li className="pb-2">
									Keynote sessions by renowned industry experts
								</li>
								<li className="pb-2">
									Panel discussions with successful entrepreneurs
								</li>
								<li className="pb-2">
									Focused workshops and breakout sessions
								</li>
								<li className="pb-2">
									One-on-one consultations with business specialists
								</li>
								<li className="pb-2">
									Exhibition area showcasing sponsors and partners
								</li>
							</ul>
						</div>
						<div className="lg:w-[40%] order-1 lg:order-2">
							<img src={Home2} alt="" className="rounded-[30px]" />
						</div>
					</div>
				</div>
			</section>

			<section className="bg-black py-16 text-white text-center">
            <div className="w-[90%] md:w-[70%] m-auto">
                <h2 className="text-[24px] md:text-[40px] pb-5">
                    We cater to various industries.
                </h2>
            </div>
            <div className="relative flex justify-center items-center w-[80%] mx-auto text-[25px] py-16 overflow-hidden">
                <button 
                    onClick={handlePrev} 
                    className={`absolute left-0 p-2 rounded-full border-2 border-white ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={currentIndex === 0}
                >
                    <FaAngleLeft />
                </button>
                <div className="flex w-full justify-center items-center">
                    {displayedIndustries.map((industry, index) => (
                        <div key={index} className="w-[80&] mx-auto text-center">
                            <h2>{industry}</h2>
                        </div>
                    ))}
                </div>
                <button 
                    onClick={handleNext} 
                    className={`absolute right-0 p-2 rounded-full border-2 border-white ${currentIndex >= industries.length - slidesToShow ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={currentIndex >= industries.length - slidesToShow}
                >
                    <FaAngleRight />
                </button>
            </div>
        </section>

			<section className="bg-white py-16">
				<div className="w-[90%] m-auto">
					<div className="flex flex-col lg:flex-row justify-center items-center w-full gap-5 lg:px-20">
						<div className="lg:w-[50%] order-2 lg:order-1 lg:pr-10">
							<p className="text-[40px] font-medium pb-2">Individual Tickets</p>
							<h2 className="text-[40px] font-bold pb-2">
								Standard paid or free tickets sold separately.
							</h2>
							<p className="py-5 font-medium text-[19px]">
								Ideal for solo attendees looking to benefit from the summit's
								offerings. This option allows for flexible and personalized
								participation.
							</p>
							<h2 className="pb-8 font-bold text-[23px]">Price: R2500</h2>
							<Link
								to="https://fixr.co/event/the-scaling-success-summit-tickets-669365093"
                target="_blank"
								className="w-full bg-[#09cbcd] px-20 py-3 font-bold rounded-full text-white"
							>
								Get Individual Ticket
							</Link>
						</div>
						<div className="lg:w-[40%] order-1 lg:order-2">
							<img src={Home3} alt="" className="rounded-[30px]" />
						</div>
					</div>
				</div>
			</section>

			<section className="bg-white py-16 border-t-2 border-t-[#ccc]">
				<div className="w-[90%] m-auto">
					<div className="flex flex-col lg:flex-row justify-center items-center w-full gap-[10%] lg:px-20">
						<div className="lg:w-[50%] order-2 lg:order-2 lg:pr-10">
							<p className="text-[40px] font-medium pb-2">Group Tickets</p>
							<h2 className="text-[40px] font-bold pb-2">
								Multiple tickets sold as a package.
							</h2>
							<p className="py-5 font-medium text-[19px]">
								Perfect for teams or small groups (2-5 people) from the same
								organization. Group tickets provide a cost effective way to
								bring colleagues along and collaborate on the insights gained
								from the summit.
							</p>
							<h2 className="pb-8 font-bold text-[23px]">
								From: R4500 per group
							</h2>
							<Link
								to="https://fixr.co/event/the-scaling-success-summit-tickets-669365093"
                target="_blank"
								className="bg-[#09cbcd] px-20 py-3 font-bold rounded-full text-white"
							>
								Get Group Ticket
							</Link>
						</div>
						<div className="lg:w-[40%] order-1 lg:order-1">
							<img src={Home4} alt="" className="rounded-[30px]" />
						</div>
					</div>
				</div>
			</section>

			<section className="bg-white py-16 border-t-2 border-t-[#ccc]">
				<div className="w-[90%] m-auto">
					<div className="flex flex-col lg:flex-row justify-center items-center w-full gap-5 lg:px-20">
						<div className="lg:w-[50%] order-2 lg:order-1 lg:pr-10">
							<p className="text-[40px] font-medium pb-2">
								Table / Booth Tickets
							</p>
							<h2 className="text-[40px] font-bold pb-2">
								A table/booth with a set number of seats.
							</h2>
							<p className="py-5 font-medium text-[19px]">
								Designed for larger groups or businesses wanting a dedicated
								space at the event. This package includes a table or booth with
								a set number of seats, offering a prime opportunity for
								networking, branding, and focused team engagement.
							</p>
							<h2 className="pb-8 font-bold text-[23px]">
								From: R8500 per table/booth
							</h2>
							<Link
								to="https://fixr.co/event/the-scaling-success-summit-tickets-669365093"
                target="_blank"
								className=" bg-[#09cbcd] px-16 lg:px-20 py-3 font-bold rounded-full text-white"
							>
								Get Table/Booth Ticket
							</Link>
						</div>
						<div className="lg:w-[40%] order-1 lg:order-2">
							<img src={Home5} alt="" className="rounded-[30px]" />
						</div>
					</div>
				</div>
			</section>

			<section className="bg-white py-16 border-t-2 border-t-[#ccc] text-center">
				<h2 className="text-center font-bold text-[30px] lg:text-[40px]">
					Speakers
				</h2>
				<div className="w-[80%] mx-auto py-16">
					<div className="flex flex-col lg:flex-row justify-center items-center w-full gap-5">
						<div className="w-[100%] lg:w-[25%]">
							<img
								src={Sp1}
								alt=""
								className="rounded-[30px] border-2 border-black"
							/>
							<p className="pt-2 font-bold">Adi Kaimowitz</p>
							<p className="py-1">CEO & Founder</p>
							<p className="font-bold">Virtual Actuary</p>
						</div>
						<div className="w-[100%] lg:w-[25%]">
							<img
								src={Sp2}
								alt=""
								className="rounded-[30px] border-2 border-black"
							/>
							<p className="pt-2 font-bold">Napsta Masinga</p>
							<p className="py-1">Creative Entrepreneur</p>
							<p className="font-bold">Comedian</p>
						</div>
						<div className="w-[100%] lg:w-[25%]">
							<img
								src={Sp3}
								alt=""
								className="rounded-[30px] border-2 border-black"
							/>
							<p className="pt-2 font-bold">Dr. Ali Kiyaei</p>
							<p className="py-1">Secretary General</p>
							<p className="font-bold">UNASA</p>
						</div>
						<div className="w-[100%] lg:w-[25%]">
							<img
								src={Sp4}
								alt=""
								className="rounded-[30px] border-2 border-black"
							/>
							<p className="pt-2 font-bold">Natalie Barlow</p>
							<p className="py-1">Marketing and Commercial</p>
							<p className="font-bold">LaLiga SA</p>
						</div>
					</div>
				</div>
				<div className="">
					<Link
						to="/speakers"
						className="bg-[#09cbcd] px-16 lg:px-20 py-3 font-bold rounded-full text-white"
					>
						Become a Speaker
					</Link>
				</div>
			</section>

			<section className="py-16 bg-black text-white text-center border-b-2 border-b-[#ccc]">
				<div className="w-[90%] lg:w-[80%] mx-auto">
					<h2 className="text-[27px] font-semibold pb-4">
						SIGN UP FOR OUR NEWSLETTER TODAY AND JOIN THE MOVEMENT TOWARDS A
						MORE SUSTAINABLE FUTURE.
					</h2>
					<p className="py-4 text-[22px]">
						BE THE FIRST TO KNOW ABOUT NEW EXCLUSIVE OFFERS
					</p>
				</div>
				<div className="w-full lg:w-[40%] mx-auto pt-4">
					<input
						type="email"
						placeholder="Email"
						className="w-[80%] py-2 rounded-md px-3 text-black"
					/>
				</div>
			</section>
		</>
	);
};

export default Home;
