import React, { useState } from "react";
import HeroBg from "../assests/images/1.jpg";

const Register = () => {
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		const form = e.target;
		const formData = new FormData(form);
		const message = `
      Full Name: ${formData.get("fullname")}
      Company Size: ${formData.get("companySize")}
      Business Challenges: ${formData.get("businessChallenges")}
      Email: ${formData.get("email")}
      Industry: ${formData.get("industry")}
      Phone Number: ${formData.get("phoneNumber")}
      Country: ${formData.get("country")}
      Ticket Type: ${formData.get("ticketType")}
    `;
		const emailData = {
			service_id: "default_service",
			template_id: "template_yn37zcb",
			user_id: "Lk_-NwPkXxWocIFUX",
			template_params: {
				fullname: formData.get("fullname"),
				companySize: formData.get("companySize"),
				businessChallenges: formData.get("businessChallenges"),
				email: formData.get("email"),
				industry: formData.get("industry"),
				phoneNumber: formData.get("phoneNumber"),
				country: formData.get("country"),
				ticketType: formData.get("ticketType"),
				message: message.trim(),
			},
		};
		try {
			// const data = new FormData(form);
			const response = await fetch(
				"https://api.emailjs.com/api/v1.0/email/send",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer Lk_-NwPkXxWocIFUX",
					},
					body: JSON.stringify(emailData),
				}
			);
			if (response.ok) {
				// Handle success (e.g., show a success message)
				console.log("Form successfully submitted");
				alert("Form successfully submitted");
			} else {
				// Handle error (e.g., show an error message)
				console.error("Form submission error");
				alert("Form submission error");
			}
		} catch (error) {
			console.error("Form submission error", error);
		} finally {
			setLoading(false);
			form.reset();
		}
	};
	return (
		<>
			<section
				className="h-[20vh] lg:h-[60vh] w-full bg-cover bg-center flex items-center justify-center"
				style={{ backgroundImage: `url(${HeroBg})` }}
			></section>
			<section className="py-5 px-2 lg:px-6 overflow-hidden">
				<div className="text-center py-5">
					<h4 className="text-xl font-bold mb-2">
						Secure Your Spot at the Scaling Success Summit!
					</h4>
					<p className="mb-4">
						Register now for the Scaling Success Summit and gain the tools and
						connections to achieve sustainable growth.
					</p>
				</div>
				<form
					className="grid grid-cols-12 gap-x-10 gap-y-4 px-4 lg:px-6"
					onSubmit={handleSubmit}
				>
					{/* Row 1 */}
					<div className="col-span-9 lg:col-span-4">
						<label
							className="block mb-1 text-sm font-medium text-gray-700"
							htmlFor="fullname"
						>
							Full Name
						</label>
						<input
							className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
							type="text"
							id="fullname"
							name="fullname"
						/>
					</div>
					<div className="col-span-9 lg:col-span-4">
						<label
							className="block mb-1 text-sm font-medium text-gray-700"
							htmlFor="companySize"
						>
							Company Size
						</label>
						<input
							className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
							type="text"
							id="companySize"
							name="companySize"
						/>
					</div>
					<div className="col-span-9 lg:col-span-4 lg:row-span-2">
						<label
							className="block mb-1 text-sm font-medium text-gray-700"
							htmlFor="businessChallenges"
						>
							What are your top 3 business growth challenges?
						</label>
						<textarea
							className="w-[100%] lg:w-full p-2 border border-gray-300 rounded h-[70%]"
							id="businessChallenges"
							name="businessChallenges"
						></textarea>
					</div>
					{/* Row 2 */}
					<div className="col-span-9 lg:col-span-4">
						<label
							className="block mb-1 text-sm font-medium text-gray-700"
							htmlFor="email"
						>
							Email Address
						</label>
						<input
							className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
							type="email"
							id="email"
							name="email"
						/>
					</div>
					<div className="col-span-9 lg:col-span-4">
						<label
							className="block mb-1 text-sm font-medium text-gray-700"
							htmlFor="industry"
						>
							Industry
						</label>
						<input
							className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
							type="text"
							id="industry"
							name="industry"
						/>
					</div>
					{/* Row 3 */}
					<div className="col-span-9 lg:col-span-4">
						<label
							className="block mb-1 text-sm font-medium text-gray-700"
							htmlFor="phoneNumber"
						>
							Phone Number
						</label>
						<input
							className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
							type="text"
							id="phoneNumber"
							name="phoneNumber"
						/>
					</div>
					<div className="col-span-9 lg:col-span-4">
						<label
							className="block mb-1 text-sm font-medium text-gray-700"
							htmlFor="country"
						>
							Country
						</label>
						<input
							className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
							type="text"
							id="country"
							name="country"
						/>
					</div>
					<div className="col-span-9 lg:col-span-4">
						<label
							className="block mb-1 text-sm font-medium text-gray-700"
							htmlFor="ticketType"
						>
							Select Ticket Type
						</label>
						<select
							className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
							id="ticketType"
							name="ticketType"
						>
							<option value="individual">Individual</option>
							<option value="group">Group</option>
						</select>
					</div>
					{/* Row 4 */}
					<div className="col-span-9 lg:col-span-4 flex justify-start mt-4">
						<button
							className={`w-[100%] lg:w-full text-white py-3 rounded ${
								loading ? "bg-gray-500" : "bg-black"
							}`}
							type="submit"
							disabled={loading}
						>
							{loading ? (
								<div className="spinner"></div>
							) : (
								"Join the Waiting List"
							)}
						</button>
					</div>
				</form>
			</section>
		</>
	);
};

export default Register;
