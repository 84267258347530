import React from 'react'
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from "react-router-dom";
import HomeLayout from './components/HomeLayout';
import Home from './Pages/Home';
import About from './Pages/About';
import Register from './Pages/Register';
import Speakers from './Pages/Speakers';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<HomeLayout />}>
        <Route index element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/register' element={<Register />} />
        <Route path='/speakers' element={<Speakers />} />
      </Route>
 
    </>
  )
);
 
function App() {
  return <RouterProvider router={router} />;
}

export default App
