import React, { useState, useRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import Logo from "../assests/images/SSS of Web logo.png";
import { Link } from "react-router-dom";

const Navbar: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleLinkClick = () => {
		setIsOpen(false);
	};

	return (
		<nav className="bg-white shadow-md">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="flex items-center justify-between h-20">
					<div className="flex items-center">
						<Link to="/">
							<img className="h-[70px] w-[200px]" src={Logo} alt="Brand" />
						</Link>
					</div>
					<div className="hidden lg:block">
						<div className="ml-10 flex items-baseline space-x-4">
							<Link
								to="/about"
								className="text-black hover:text-[#09cbcd] px-3 py-2 rounded-md text-[18px] font-medium"
							>
								About
							</Link>
							<Link
								to="#"
								className="text-black hover:text-[#09cbcd] px-3 py-2 rounded-md text-[18px] font-medium"
							>
								Agenda
							</Link>
							<Link
								to="/speakers"
								className="text-black hover:text-[#09cbcd] px-3 py-2 rounded-md text-[18px] font-medium"
							>
								Speaker
							</Link>
							<Link
								to="#"
								className="text-black hover:text-[#09cbcd] px-3 py-2 rounded-md text-[18px] font-medium"
							>
								Exhibition
							</Link>
						</div>
					</div>
					<div className="hidden lg:block">
						<Link
							to="/register"
							className="bg-[#09cbcd] text-white px-14 py-3 rounded-[30px] text-[17px] font-medium"
						>
							Registration
						</Link>
					</div>
					<div className="-mr-2 flex lg:hidden">
						<button
							onClick={() => setIsOpen(!isOpen)}
							type="button"
							className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
							aria-controls="mobile-menu"
						>
							<span className="sr-only">Open main menu</span>
							{!isOpen ? (
								<svg
									className="block h-6 w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M4 6h16M4 12h16m-7 6h7"
									/>
								</svg>
							) : (
								<svg
									className="block h-6 w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							)}
						</button>
					</div>
				</div>
			</div>

			<Transition
				show={isOpen}
				enter="transition ease-out duration-100 transform"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="transition ease-in duration-75 transform"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				{(ref) => (
					<div className="lg:hidden" id="mobile-menu" ref={menuRef}>
						<div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
							<Link
								to="/about"
								className="text-black hover:text-[#09cbcd] block px-3 py-2 rounded-md text-base font-medium"
								onClick={handleLinkClick}
							>
								About
							</Link>
							<Link
								to="#"
								className="text-black block px-3 py-2 rounded-md text-base font-medium"
								onClick={handleLinkClick}
							>
								Agenda
							</Link>
							<Link
								to="/speakers"
								className="text-black hover:text-[#09cbcd] block px-3 py-2 rounded-md text-base font-medium"
								onClick={handleLinkClick}
							>
								Speaker
							</Link>
							<Link
								to="#"
								className="text-black hover:text-[#09cbcd] block px-3 py-2 rounded-md text-base font-medium"
								onClick={handleLinkClick}
							>
								Exhibition
							</Link>
						</div>
						<div className="px-2 pb-3 sm:px-3">
							<Link
								to="/register"
								className="bg-[#09cbcd] text-white w-full px-3 py-2 rounded-md text-base font-medium"
								onClick={handleLinkClick}
							>
								Registration
							</Link>
						</div>
					</div>
				)}
			</Transition>
		</nav>
	);
};

export default Navbar;
