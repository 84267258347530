import React, { useEffect, useState } from "react";
import HeroBg from "../assests/images/4.jpg";

const Speakers = () => {
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [uploading, setUploading] = useState(false);
	const [image, setImage] = useState("");
	const [isChecked, setIsChecked] = useState(false);

	const [step1Valid, setStep1Valid] = useState(false);
	const [step2Valid, setStep2Valid] = useState(false);

	const [formData, setFormData] = useState<any>({
		fullname: "",
		address: "",
		socialMedia: "",
		email: "",
		city: "",
		portfolio: "",
		phoneNumber: "",
		country: "",
		upload: "",
		format: "",
		proposedTopic: "",
		describeTopic: "",
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setFormData((prevState: any) => ({
			...prevState,
			[name]: value,
		}));
	};

	useEffect(() => {
		const step1RequiredFields = [
			"fullname",
			"address",
			"email",
			"city",
			"phoneNumber",
			"country",
		];
		setStep1Valid(
			step1RequiredFields.every((field) => formData[field].trim() !== "")
		);

		const step2RequiredFields = ["proposedTopic", "describeTopic"];
		setStep2Valid(
			step2RequiredFields.every((field) => formData[field].trim() !== "")
		);
	}, [formData]);

	const HandleNext = () => {
		setStep((prev) => prev + 1);
	};

	const HandlePrev = () => {
		setStep((prev) => prev - 1);
	};

	const HandleSubmit = async (e: any) => {
		e.preventDefault();
		setLoading(true);

		const form = e.target;
		const formData = new FormData(form);
		const message = `
    Full Name: ${formData.get("fullname")}
    Address: ${formData.get("address")}
    Social Media Accounts: ${formData.get("socialMedia")}
    Email: ${formData.get("email")}
    City: ${formData.get("city")}
    Portfolio Website: ${formData.get("portfolio")}
    Phone Number: ${formData.get("phoneNumber")}
    Country: ${formData.get("country")}
    Portfolio Material: ${image}
    Preferred Format: ${formData.get("format")}
    Proposed Topic: ${formData.get("proposedTopic")}
    Describe Topic: ${formData.get("describeTopic")}
    `;
		const emailData = {
			service_id: "default_service",
			template_id: "template_yn37zcb",
			user_id: "Lk_-NwPkXxWocIFUX",
			template_params: {
				fullname: formData.get("fullname"),
				address: formData.get("address"),
				socialMedia: formData.get("socialMedia"),
				email: formData.get("email"),
				city: formData.get("city"),
				portfolio: formData.get("portfolio"),
				phoneNumber: formData.get("phoneNumber"),
				country: formData.get("country"),
				upload: image,
				format: formData.get("format"),
				proposedTopic: formData.get("proposedTopic"),
				describeTopic: formData.get("describeTopic"),
				message: message.trim(),
			},
		};

		try {
			// const data = new FormData(form);
			const response = await fetch(
				"https://api.emailjs.com/api/v1.0/email/send",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer Lk_-NwPkXxWocIFUX",
					},
					body: JSON.stringify(emailData),
				}
			);
			if (response.ok) {
				// Handle success (e.g., show a success message)
				console.log("Form successfully submitted");
				alert("Form successfully submitted");
			} else {
				// Handle error (e.g., show an error message)
				console.error("Form submission error");
				alert("Form submission error");
			}
		} catch (error) {
			console.error("Form submission error", error);
		} finally {
			setLoading(false);
			form.reset();
		}
	};

	const uploadFileHandler = async (e: any) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append("file", file);
		formData.append("image", file);
		formData.append("upload_preset", "ecommerceapp");
		formData.append("cloud_name", "betadisney");

		setUploading(true);

		fetch("https://api.cloudinary.com/v1_1/betadisney/image/upload", {
			method: "POST",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data);
				setImage(data.url);
				setUploading(false);
			})
			.catch((err) => {
				console.log(err);
				setUploading(false);
			});
	};

	const handleCheckboxChange = (e: any) => {
		setIsChecked(e.target.checked);
	};

	return (
		<>
			<section
				className="h-[20vh] lg:h-[60vh] w-full bg-cover bg-center flex items-center justify-center"
				style={{ backgroundImage: `url(${HeroBg})` }}
			></section>

			<section className="py-5 px-2 lg:px-6 overflow-hidden">
				{step && step !== 3 && (
					<div className="text-center py-5 ">
						<h4 className="text-xl font-bold mb-2">
							Share Your Expertise, Shape the Future
						</h4>
						<p className="mb-4">
							The Scaling Success Summit stage awaits your brilliance! Join a
							community of thought leaders and industry luminaries as you share
							your insights and ignite new possibilities.
						</p>
					</div>
				)}
				{step && step === 3 && (
					<div className="py-5 px-6">
						<h4 className="text-xl font-bold mb-2 text-center">
							We value your privacy!
						</h4>
						<p className="mb-4">
							The information you provide in your speaker application will only
							be used to review your qualifications and, if selected, manage
							your participation in the event. We may occasionally contact you
							about future speaking opportunities or events relevant to your
							expertise.
						</p>
					</div>
				)}
				<form
					className="grid grid-cols-12 gap-x-10 gap-y-4 px-4 lg:px-6"
					onSubmit={HandleSubmit}
				>
					{step && step === 1 && (
						<>
							{/* Row 1 */}
							<div className="col-span-9 lg:col-span-4">
								<label
									className="block mb-1 text-sm font-medium text-gray-700"
									htmlFor="fullname"
								>
									Full Name
								</label>
								<input
									className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
									type="text"
									id="fullname"
									name="fullname"
									value={formData.fullname}
									onChange={handleChange}
								/>
							</div>
							<div className="col-span-9 lg:col-span-4">
								<label
									className="block mb-1 text-sm font-medium text-gray-700"
									htmlFor="address"
								>
									What is your address?*
								</label>
								<input
									className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
									type="text"
									id="address"
									name="address"
									value={formData.address}
									onChange={handleChange}
								/>
							</div>
							<div className="col-span-9 lg:col-span-4">
								<label
									className="block mb-1 text-sm font-medium text-gray-700"
									htmlFor="socialMedia"
								>
									Social Media Accounts
								</label>
								<textarea
									className="w-[100%] lg:w-full p-2 border border-gray-300 rounded h-[70%]"
									id="socialMedia"
									name="socialMedia"
									value={formData.socialMedia}
									onChange={handleChange}
								></textarea>
							</div>
							{/* Row 2 */}
							<div className="col-span-9 lg:col-span-4">
								<label
									className="block mb-1 text-sm font-medium text-gray-700"
									htmlFor="email"
								>
									Email Address
								</label>
								<input
									className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
									type="email"
									id="email"
									name="email"
									value={formData.email}
									onChange={handleChange}
								/>
							</div>
							<div className="col-span-9 lg:col-span-4">
								<label
									className="block mb-1 text-sm font-medium text-gray-700"
									htmlFor="city"
								>
									City / Suburb
								</label>
								<input
									className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
									type="text"
									id="city"
									name="city"
									value={formData.city}
									onChange={handleChange}
								/>
							</div>
							<div className="col-span-9 lg:col-span-4">
								<label
									className="block mb-1 text-sm font-medium text-gray-700"
									htmlFor="portfolio"
								>
									Portfolio Website
								</label>
								<input
									className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
									type="text"
									id="portfolio"
									name="portfolio"
									value={formData.portfolio}
									onChange={handleChange}
								/>
							</div>
							{/* Row 3 */}
							<div className="col-span-9 lg:col-span-4">
								<label
									className="block mb-1 text-sm font-medium text-gray-700"
									htmlFor="phoneNumber"
								>
									Phone Number
								</label>
								<input
									className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
									type="tel"
									id="phoneNumber"
									name="phoneNumber"
									value={formData.phoneNumber}
									onChange={handleChange}
								/>
							</div>
							<div className="col-span-9 lg:col-span-4">
								<label
									className="block mb-1 text-sm font-medium text-gray-700"
									htmlFor="country"
								>
									Country
								</label>
								<input
									className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
									type="text"
									id="country"
									name="country"
									value={formData.country}
									onChange={handleChange}
								/>
							</div>
							<div className="col-span-9 lg:col-span-4">
								<label
									className="block mb-1 text-sm font-medium text-gray-700"
									htmlFor="upload"
								>
									Portfolio material upload (Optional)
								</label>
								{image ? (
									<>
										<input
											className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
											type="text"
											id="upload"
											name="upload"
											value={image}
											readOnly
										/>
										<span className="text-[10px] text-red-400">
											Image Uploaded Successfully
										</span>
									</>
								) : (
									<div className="relative">
										<input
											className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
											type="file"
											id="upload"
											name="upload"
											onChange={uploadFileHandler}
										/>
										{uploading && (
											<div className="w-4 h-4 border-4 border-[#012E63] border-dotted rounded-full animate-spin mr-4 absolute right-1 top-4"></div>
										)}
									</div>
								)}
							</div>
							<div className="col-span-9 lg:col-span-4 flex justify-start mt-4">
								<button
									className={`text-white py-3 px-10 rounded ${
										step1Valid ? "bg-black" : "bg-gray-500 cursor-not-allowed"
									}`}
									onClick={HandleNext}
									disabled={!step1Valid}
								>
									Next
								</button>
							</div>
						</>
					)}

					{step && step === 2 && (
						<>
							<div className="col-span-9 lg:col-span-6">
								<label
									className="block mb-1 text-sm font-medium text-gray-700"
									htmlFor="fullname"
								>
									Which format would you prefer ?
								</label>
								<div className="w-[100%] lg:w-full py-3 border border-gray-300 rounded flex justify-between px-5 items-center mb-1">
									<span>Keynote</span>
									<input
										className=""
										type="radio"
										name="format"
										onChange={handleChange}
									/>
								</div>
								<div className="w-[100%] lg:w-full py-3 border border-gray-300 rounded flex justify-between px-5 items-center mb-1">
									<span>Panel</span>
									<input
										className=""
										type="radio"
										name="format"
										onChange={handleChange}
									/>
								</div>
								<div className="w-[100%] lg:w-full py-3 border border-gray-300 rounded flex justify-between px-5 items-center mb-1">
									<span>Workshop</span>
									<input
										className=""
										type="radio"
										name="format"
										onChange={handleChange}
									/>
								</div>
								<div className="w-[100%] lg:w-full py-3 border border-gray-300 rounded flex justify-between px-5 items-center mb-1">
									<span>Others</span>
									<input
										className=""
										type="radio"
										name="format"
										onChange={handleChange}
									/>
								</div>
							</div>

							<div className="col-span-9 lg:col-span-6">
								<div className="mb-2">
									<label
										className="block mb-1 text-sm font-medium text-gray-700"
										htmlFor="proposedTopic"
									>
										What is your proposed topic?
									</label>
									<input
										className="w-[100%] lg:w-full p-2 border border-gray-300 rounded"
										type="text"
										id="proposedTopic"
										name="proposedTopic"
										value={formData.proposedTopic}
										onChange={handleChange}
									/>
								</div>
								<div className="mb-1">
									<label
										className="block mb-1 text-sm font-medium text-gray-700"
										htmlFor="describeTopic"
									>
										Describe your proposed topic?
									</label>
									<textarea
										className="w-[100%] lg:w-full p-2 border border-gray-300 rounded h-[70%]"
										id="describeTopic"
										name="describeTopic"
										value={formData.describeTopic}
										onChange={handleChange}
										placeholder="Use as much detail as you feel necessary"
										rows={5}
									></textarea>
								</div>
							</div>
							<div className="col-span-9 lg:col-span-6">
								<button
									className={`text-white py-3 px-10 rounded bg-black mr-4`}
									onClick={HandlePrev}
								>
									Back
								</button>
								<button
									className={`text-white py-3 px-10 rounded ${
										step2Valid ? "bg-black" : "bg-gray-500 cursor-not-allowed"
									}`}
									onClick={HandleNext}
									disabled={!step2Valid}
								>
									Next
								</button>
							</div>
						</>
					)}
					{step && step === 3 && (
						<>
							<div className="col-span-9 flex items-center gap-3">
								<input
									type="checkbox"
									className="w-[30px] h-[30px]"
									onChange={handleCheckboxChange}
								/>{" "}
								<span>
									I agree to receive other communications from The Scaling
									Success Team.
								</span>
							</div>
							<div className="col-span-12 mb-4">
								<span>
									You can opt out of these communications at any time. For more
									details on our privacy practices, please refer to our Privacy
									Policy ([link to Privacy Policy]). By submitting your
									application, you consent to the collection and use of your
									information as described above.
								</span>
							</div>
							<div className="col-span-9 lg:col-span-6">
								<button
									className={`text-white py-3 px-10 rounded bg-black mr-4`}
									onClick={HandlePrev}
								>
									Back
								</button>
								<button
									className={`text-white py-3 px-10 rounded ${
										isChecked ? "bg-black" : "bg-gray-500 cursor-not-allowed"
									}`}
									type="submit"
									disabled={!isChecked}
								>
									Submit
								</button>
							</div>
						</>
					)}
				</form>
			</section>
		</>
	);
};

export default Speakers;
