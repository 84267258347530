import React from "react";
import HeroBg from "../assests/images/2.jpg";
import AboutImg from "../assests/images/3.jpg";

const About = () => {
	return (
		<>
			<section
				className="h-[20vh] lg:h-[60vh] w-full bg-cover bg-center flex items-center justify-center"
				style={{ backgroundImage: `url(${HeroBg})` }}
			></section>
			<section className="px-1 lg:px-5 py-10">
				<div className="w-[100%] lg:w-[60%] mx-auto text-center">
					<h1 className="text-[30px] lg:text-[40px] font-bold pb-2 lg:pb-8">
						Empowering Businesses to Grow.
					</h1>
					<p className="text-[20px] lg:px-10 leading-6 pb-5 font-medium">
						To create a thriving entrepreneurial ecosystem that fosters
						innovation, collaboration, and successful business scaling.
					</p>
				</div>
			</section>
			<section className="py-10 px-6 lg:px-24">
				<h2 className="text-[25px]">Who we are</h2>
				<div className="flex flex-col lg:flex-row w-full gap-8 lg:justify-between items-center">
					<div className="w-[100%] lg:w-[50%]">
						<p className="text-[30px] lg:text-[40px] font-bold leading-tight pb-6">
							Making a difference, one connection at a time.
						</p>
						<p className=" text-gray-500">
							The Scaling Success Summit is a highly-anticipated one-day event
							that brings together entrepreneurs, business leaders, industry
							experts and investors. This summit provides a dynamic platform for
							acquiring actionable strategies, fostering valuable connections,
							and receiving personalized guidance to scale businesses
							sustainably.
						</p>
					</div>
					<div className="w-[100%] lg:w-[40%]">
						<img src={AboutImg} alt="" className="rounded-[40px]" />
					</div>
				</div>
			</section>
			<section className="py-16 lg:px-24 bg-black text-white">
				<div className="text-center w-[96%] lg:w-[80%] m-auto">
					<p className="text-[25px] lg:text-[40px] pb-6 lg:pb-16">
						Connect - Collaborate - Conquer. The Scaling Success Summit for
						Growth-Minded Leaders.
					</p>
					<p className="text-[16px] lg:text-[20px] pb-10 px-5 lg:px-16">
						The greatest danger for most of us is not that our aim is too high
						and we miss it, but that it is too low and we reach it. –
						Michelangelo
					</p>
				</div>
			</section>

			<section className="py-10 bg-white">
				<p className="text-center font-bold lg:text-[30px]">
					What’s in it for you?
				</p>
				<div className="pt-10 flex w-full flex-col lg:flex-row flex-wrap gap-5 items-center px-6 lg:px-24">
					<div className="w-[100%] lg:w-[48%]">
						<h1 className="text-[35px] font-medium pb-5">For Entrepreneurs:</h1>
						<ul className="list-disc px-5 font-normal text-[19px]">
							<li>
								Learn proven growth tactics from relatable success stories
							</li>
							<li>
								Get advice on specific challenges from subject matter experts
							</li>
							<li>
								Discover the latest tools and resources to scale efficiently
							</li>
							<li>
								Access exclusive networking opportunities to find partners,
								investors etc.
							</li>
						</ul>
					</div>
					<div className="w-[100%] lg:w-[48%]">
						<h1 className="text-[35px] font-medium pb-5">
							For Business Leaders:
						</h1>
						<ul className="list-disc px-5 font-normal text-[19px]">
							<li>
								Gain insights into fostering an entrepreneurial, innovative
								culture
							</li>
							<li>
								Explore best practices for corporate entrepreneurship
								initiatives
							</li>
							<li>Identify potential investment/acquisition opportunities</li>
							<li>Connect with entrepreneurial talent to drive new ventures</li>
						</ul>
					</div>
					<div className="w-[100%] lg:w-[48%]">
						<h1 className="text-[35px] font-medium pb-5">
							For Investors/Enablers:
						</h1>
						<ul className="list-disc px-5 font-normal text-[19px]">
							<li>
								Find promising, scalable businesses to invest in or support
							</li>
							<li>
								Understand key drivers and trends fueling entrepreneurial growth
							</li>
							<li>
								Position your brand as an enabler of entrepreneurial success
							</li>
							<li>Network with entrepreneurial ecosystem builders</li>
						</ul>
					</div>
				</div>
			</section>
		</>
	);
};

export default About;
